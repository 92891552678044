import { clearStyles, getPopup, setStyles, setPopupSize } from "./common";
import { triggerIcons } from "./icons";
import { closeIcon, userIcon } from "./icons";
import { getIsMobile, createDecorElement } from './globalFunction'

export const showTrigger = () => {
  const nameIdWrapper = 'boostinglead-global-wrapper'
  const div = document.createElement("div");
  const decorDiv = createDecorElement();
  div.id = nameIdWrapper
  const iframe = document.createElement("iframe");
  let isInitPlayer = false;
  let YTplayer = null;
  let videoId = null;
  let backdrop = null;

  const isMobile = getIsMobile()

  let isShorts = false

  let triggerWidthNormal = '200px'
  let triggerWidthBig = '310px'
  let triggerWidthNormalShort = '140px'
  let triggerWidthBigShort = '200px'

  const positionTriger = {
    1: {
      left: "-5px",
      top: "90px",
    },
    2: {
      right: "-5px",
      top: "90px",
      flexDirection: "row-reverse",
    },
    3: {
      left: "-5px",
      top: "50%",
    },
    4: {
      right: "-5px",
      top: "50%",
      flexDirection: "row-reverse",
    },
    5: {
      left: "-5px",
      bottom: "90px",
    },
    6: {
      right: "-5px",
      bottom: "90px",
      flexDirection: "row-reverse",
    },
  };

  const positionTrigerManager = {
    1: {
      left: isMobile ? '16px' : "40px",
      top: isMobile ? '16px' : "40px",
    },
    2: {
      right: isMobile ? '16px' : "40px",
      top: isMobile ? '16px' : "40px",
    },
    3: {
      left: isMobile ? '16px' : "40px",
      top: "50%",
    },
    4: {
      right: isMobile ? '16px' : "40px",
      top: "50%",
    },
    5: {
      left: isMobile ? '16px' : "40px",
      bottom: isMobile ? '16px' : "40px",
    },
    6: {
      right: isMobile ? '16px' : "40px",
      bottom: isMobile ? '16px' : "40px",
    },
  };

  let wasActionWhitVideo = false

  if (!window.onYouTubeIframeAPIReady) {
    window.onYouTubeIframeAPIReady = () => {
      YTplayer = new YT.Player("trigger-player", {
        playerVars: {
            autoplay: 1,
            playsinline: 1,
            controls: 1,
            rel: 0
        },
        events: {
          onStateChange: (event) => {
            // Цей функціонал зроблений для того, щоб можно було розширювати блок
            // Коли відео програється та повертати назад, коли пауза
            const globalWrapper = document.getElementById(nameIdWrapper)
            if(wasActionWhitVideo && globalWrapper) {
              if(event.data === YT.PlayerState.PAUSED) {
                globalWrapper.style.width = isShorts ? triggerWidthNormalShort : triggerWidthNormal
              }
  
              if(event.data === YT.PlayerState.PLAYING || event.data === YT.PlayerState.BUFFERING) {
                  globalWrapper.style.width = isShorts ? triggerWidthBigShort : triggerWidthBig
              }
            }

            if (event.data === YT.PlayerState.ENDED) {
                setTimeout(() => {
                  event.target.playVideo();
                },50)
            }

            if (event.data === YT.PlayerState.PAUSED && event.target.playerInfo.muted) {
              event.target.unMute();
              event.target.playVideoAt(0);

              wasActionWhitVideo = true
            }
          },
        }
      });
      console.log("onYouTubeIframeAPIReady", YTplayer);
    };
  }

  const innerFunc = async (body, quizConfig) => {
    if(div) {
      div.innerHTML = ""
    }

    if (quizConfig.trigger_type === 1) {
      const divStyles = {
        display: "flex",
        position: "fixed",
        height: "30px",
        backgroundColor: "transparent",
        color: quizConfig.icon_text_color,
        alignItems: "center",
        fontWeight: "500",
        fontSize: "13px", //10
        lineHeight: "1.23", //1.6
        cursor: "pointer",
        zIndex: "9999999999",
        ...positionTriger[quizConfig.icon_position],
      };

      div.onclick = () => {
        backdrop = getPopup();
        body.append(backdrop);
      };

      clearStyles(div);
      setStyles(div, divStyles);

      const res = await fetch(triggerIcons[quizConfig.icon_icon_type]);
      const icon = await res.text();

      const textBlock = document.createElement('div')

      setStyles(textBlock, {
        position: "relative",
        display: "inline-block",
        padding: "7px 20px 7px 25px",
        borderRadius: "5px",
        overflow: "hidden",
        backgroundColor: `${quizConfig.icon_trigger_color}`,
      })

      textBlock.innerHTML = quizConfig.icon_trigger_text
      textBlock.append(decorDiv)

      const iconBlock = document.createElement('div')

      setStyles(iconBlock, {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "20px",
        minWidth: "20px",
        height: "20px",
        marginInline: "10px",
        backgroundColor: "transparent",
        color: `${quizConfig.icon_text_color}`,
        borderRadius: "50%", 
      })

      iconBlock.innerHTML = icon

      div.append(textBlock)
      div.append(iconBlock)
    }

    if (quizConfig.trigger_type === 2) {
      isShorts = quizConfig.video_link?.includes("shorts");

      if (!isInitPlayer) {
        var tag = document.createElement("script");

        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        isInitPlayer = true;
      }

      let position = { left: 0, top: 0 };
      switch (quizConfig.video_position) {
        case 1:
          position = {
            left: isMobile ? '16px' : "40px",
            bottom: isMobile ? '16px' : "40px",
          };
          break;
        case 2:
          position = {
            right: isMobile ? '16px' : "40px",
            bottom: isMobile ? '16px' : "40px",
          };
          
          break;

          default: 
          position = {
            left: isMobile ? '16px' : "40px",
            bottom: isMobile ? '16px' : "40px",
          };

      }
      const divStyles = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "fixed",
        width: isShorts ? triggerWidthNormalShort : triggerWidthNormal,
        height: "auto",
        borderRadius: "5px",
        overflow: "hidden",
        color: quizConfig.video_text_color,
        alignItems: "start",
        fontWeight: "500",
        fontSize: "13px", //10
        lineHeight: "1.23", //1.6
        cursor: "pointer",
        zIndex: "9999999999",
        ...position,
      };

      const resClose = await fetch(closeIcon);
      const iconClose = await resClose.text();

      // div.onmouseover = () => {
      //   if (quizConfig.video_play_on_hover) YTplayer.playVideo();
      // };
      // div.onmouseleave = () => {

      //   if (quizConfig.video_play_on_hover) YTplayer?.pauseVideo();
      // };

      clearStyles(div);
      setStyles(div, divStyles);

      const videoContainer = document.createElement("div");
      videoContainer.style.boxSizing = "border-box";
      videoContainer.style.width = "100%";
      // videoContainer.style.aspectRatio = isShorts ? '9/16' : '16/9';
      videoContainer.style.overflow = "hidden";
      videoContainer.style.position = "relative";
      videoContainer.style.marginBottom = "-42px";
      videoContainer.style.zIndex = "-1";
      if(isShorts) {
        videoContainer.style.paddingTop = "calc(177.78% + 42px)";
      } else {
        videoContainer.style.paddingTop = "calc(56.25% + 42px)";
      }

      const rx =
        /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/; // };
      videoId = (quizConfig.video_link?.match(rx) ?? [null, null])[1]; //new URL(quizConfig.video_link).searchParams.get("v");
      iframe.id = "trigger-player";
      iframe.src = `https://www.youtube.com/embed/${videoId}?controls=0&fs=0&modestbranding=0&playsinline=1&rel=0&showinfo=0&enablejsapi=1&mute=1&loop=1&autoplay=1&playlist=${videoId}`;
      iframe.style.borderRadius = "5px 5px 0px 0px";
      iframe.style.border = "none";
      iframe.style.position = "absolute";
      iframe.style.top = "0px";
      iframe.style.left = "0px";
      iframe.style.width = isShorts ? "calc(100% * 16 / 9 * 16 / 9 * 5)" : "100%";

      const marginInline = isShorts ? "calc(-100% * 16 / 9 * 16 / 9 / 2 + 50%)" : "";

      iframe.style.marginLeft = marginInline;
      iframe.style.marginRight = marginInline;

      const marginBlock = isShorts ? "" : "-102px";
      iframe.style.marginTop = marginBlock;
      iframe.style.marginBottom = marginBlock;
      iframe.style.display = "block";
      iframe.style.height = isShorts ? "calc(100% * 5)" : "calc(100% + 162px)";
      if (isShorts) {
        iframe.style.transform = "scale(0.20)";
        iframe.style.transformOrigin = "top left";
      } else {
        iframe.style.transform = "";
        iframe.style.transformOrigin = "";
      }

      // iframe.setAttribute("frameborder", 0);
      // iframe.setAttribute("fs", 0);
      //iframe.onclick = () => {console.log('iframe.click')};

      const res = await fetch(triggerIcons[quizConfig.video_icon_type]);
      const icon = await res.text();

      div.innerHTML = "";
      if (videoId) {
        videoContainer.append(iframe);
        div.append(videoContainer);
      }

      div.onclick = ({target}) => {
        if(target.closest("button[data-action='close']")) {
          div.remove();
        } else {
          backdrop = getPopup();
          body.append(backdrop);
        }
      };

      const labelVideo = document.createElement("div");
      labelVideo.innerHTML = `
      <span lang="ru" style="margin-right: 10px; hyphens: auto;">${quizConfig.video_trigger_text}</span> 
      <span>${icon}</span>
      `;

      const iconCloseElement = document.createElement("button");

      const iconCloseElementStyle = {
        border: "none",
        position: "absolute",
        top: "2px",
        right: "4px",
        padding: "0",
        lineHeight: "1",
        background: "transparent",
        cursor: "pointer",
      };
      setStyles(iconCloseElement, iconCloseElementStyle);
      iconCloseElement.dataset.action = 'close'

      const labelVideoStyles = {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        backgroundColor: quizConfig.video_trigger_color,
        padding: "10px 10px 10px 20px",
        borderRadius: "0 0 5px 5px",
        boxSizing: "border-box",
        overflow: "hidden"
      };
      clearStyles(labelVideo);
      setStyles(labelVideo, labelVideoStyles);

      
      labelVideo.append(decorDiv);
      div.append(labelVideo);
      iconCloseElement.innerHTML = iconClose
      div.append(iconCloseElement);
    }

    if (quizConfig.trigger_type === 3) {
      let position = positionTrigerManager[quizConfig.manager_position];
      const divStyles = {
        display: "flex",
        flexDirection: "row",
        position: "fixed",
        minWidth: "200px",
        width: "auto",
        maxWidth: "300px",
        height: "auto",
        borderRadius: "5px",
        backgroundColor: quizConfig.manager_trigger_color ?? "#fff",
        color: quizConfig.manager_text_color ?? "#000",
        alignItems: "start",
        fontWeight: "400",
        fontSize: "10px", //8
        lineHeight: "2.0",
        padding: "10px",
        cursor: "pointer",
        zIndex: "9999999999",
        overflow: "hidden",
        ...position,
      };
      div.onclick = ({target}) => {
        if(target.closest("button[data-action='close']")) {
          div.remove();
        } else {
          backdrop = getPopup();
          body.append(backdrop);
        }
      };

      clearStyles(div);
      setStyles(div, divStyles);

      const resClose = await fetch(closeIcon);
      const iconClose = await resClose.text();

      // const resUser = await fetch(userIcon);
      // const iconUser = await res.text();

      div.innerHTML = `<button 
      data-action = "close"
      style="border: none;
          position: absolute;
          top: 4px;
          right: 4px;
          padding: 0;
          line-height: 1;
          background: transparent;
          cursor: pointer;"
      >${iconClose}</button>

      <div style="width: 40px;
          min-width: 40px;
          height: 40px;
          margin-right: 20px;
          border-radius: 5px;
          overflow: hidden;">
        <img src="${quizConfig.manager_image ?? userIcon}" title="photo" 
            style="display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center center;" />
      </div>

      <div style="display: inline-flex; flex-direction: column;"> 
        <h1 style=" margin: 0;
          font-style: normal;
          font-weight: 700;
          font-size: 13px; 
          line-height: 1.45;">${quizConfig.manager_name}</h1>
        <p style="margin: 0; font-style: normal; font-size: 10px; line-height: 1.6; word-break: break-word;">${quizConfig.manager_message
        }</p>
      <div>`;
      div.append(decorDiv)
    }

    return innerFunc;
  };

  let timeout = null;
  const result = {
    isInit: false,
    init: function (body, quizConfig, isPreview) {
      innerFunc(body, quizConfig);
      if (quizConfig.trigger_type === 1) {
        timeout = setTimeout(
          () => body.append(div),
          isPreview ? 0 : quizConfig.icon_opening_time * 1000
        );
      }
      if (quizConfig.trigger_type === 2) {
        body.append(div);
      }
      if (quizConfig.trigger_type === 3) {
        body.append(div);
      }
      this.isInit = true;
    },
    update: (body, quizConfig) => {
      if (quizConfig.trigger_type === 2) {
        if (timeout) clearTimeout(timeout);
      }

      innerFunc(body, quizConfig);
    },
    remove: function () {
      div.remove();
      //console.log("remove trigger", { backdrop });
      backdrop?.remove();
      window.removeEventListener("resize", setPopupSize);
      if (timeout) clearTimeout(timeout);
      timeout = null;
      this.isInit = false;
    },
  };

  return result;
};
