import { setStyles } from './common'

export const getIsMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    const isMobileUserAgent = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent)
    const isSmallScreen = document?.documentElement?.clientWidth <= 767

    return isMobileUserAgent || isSmallScreen
}

export const createDecorElement = (speed) => {
    const decorDiv = document.createElement('div')

    const decorDivStyle = {
        position: 'absolute',
        top: '-3px',
        left: '-70px',
        width: '40px',
        height: 'calc(100% + 6px)',
        backgroundColor: 'rgba(255,255,255, .3)',
        transform: 'skewX(-30deg)',
        animation: `slideDecorBoostinglead ${speed ?? '3'}s infinite linear`,
        pointerEvents: 'none',
    }

    setStyles(decorDiv, decorDivStyle)

    const styleSheet = document.createElement('style')
    styleSheet.type = 'text/css'
    styleSheet.innerHTML = `
        @keyframes slideDecorBoostinglead {
          0% {
            left: -70px;
          }
          20%, 100% {
            left: calc(100% + 40px);
          }
        }
      `
    document.head.appendChild(styleSheet)


    return decorDiv
}
