import { clearStyles, getPopup, setStyles, setPopupSize } from "./common";
import { bannerIcons } from "./icons";
import { createDecorElement } from "./globalFunction";

export const showBanner = () => {
  const div = document.createElement("div");
  const image = document.createElement("div");
  const textBlock = document.createElement("div");
  const iconBlock = document.createElement("span");
  const decorDiv = createDecorElement('5');
  let backdrop = null;

  const innerFunc = async (body, quizConfig) => {
    const divStyles = {
      maxWidth: "calc(100% - 30px)",
      width: "510px",
      minHeight: "50px",
      height: "50px",
      position: "fixed",
      left: "30px",
      display: "flex",
      alignItems: "center",
      padding: "10px 20px 10px 40px",
      backgroundColor: quizConfig.banner_trigger_color,
      color: quizConfig.banner_text_color,
      borderRadius: "5px",
      fontSize: "12px", //10
      cursor: "pointer",
      zIndex: "9999999999",
      boxSizing: "border-box",
      overflow: "hidden",
    };

    switch (quizConfig.banner_position) {
      case 1:
        divStyles.top = "30px";
        break;
      case 2:
        divStyles.bottom = "30px";
        break;
      default:
    }

    clearStyles(div);
    setStyles(div, divStyles);

    div.onclick = () => {
      backdrop = getPopup(quizConfig.quiz);
      body.append(backdrop);
    };

    const imageStyles = {
      width: "180px",
      height: "calc(100% + 20px)",
      // marginRight: "20px",
      backgroundImage: `url(${quizConfig.banner_image})`,
      backgroundColor: "transparent",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    };
    clearStyles(image);
    setStyles(image, imageStyles);

    textBlock.innerHTML = `
    <div style="font-style: normal; font-weight: 700; font-size: 13px; line-height: 1.25;">${quizConfig.banner_trigger_text}</div>
    <div style="font-style: normal; font-weight: 400; font-size: 12px; line-height: 1;">${quizConfig.banner_trigger_description}</div>`;
    textBlock.style.width = "220px";
    textBlock.style.marginRight = "10px";

      const res = await fetch(bannerIcons[quizConfig.manager_icon_type ?? 1]);
      const icon = await res.text();

      iconBlock.innerHTML = `${icon}`;
      iconBlock.style.color = quizConfig.banner_text_color;
      iconBlock.style.lineHeight = "1";
      iconBlock.style.marginLeft = "20px";
      resizeFunc();
  };

  const resizeFunc = async() => {
    if (window.innerWidth < 800) {
      iconBlock.style.display = "none";
      div.style.padding = "10px 0px 10px 10px"
      textBlock.style.width = "300px";
    } else {
      iconBlock.style.display = "block";
      div.style.padding = "10px 20px 10px 40px";
      textBlock.style.width = "220px";
    }
  }

  const result = {
    isInit: false,
    init: function (body, quizConfig) {
      innerFunc(body, quizConfig);
      window.addEventListener("resize", resizeFunc);
      div.append(textBlock);
      div.append(image);
      div.append(iconBlock);
      div.append(decorDiv);
      body.append(div);
      this.isInit = true;
    },
    update: innerFunc,
    remove: function () {
      div.remove();
      backdrop?.remove();
      window.removeEventListener("resize", setPopupSize);
      window.removeEventListener("resize", resizeFunc);
      this.isInit = false;
    },
  };

  return result;
};
